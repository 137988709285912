import React, { ReactChildren, ReactChild} from 'react';
import * as UAParser from 'ua-parser-js';

const uaParser = new UAParser.UAParser();
export interface Props {
    children: ReactChild | ReactChildren;
}

const BrowserView = ({ children }: Props) => {
    if (!isTouchDevice()) return (<> {children} </>)
    return null;
}

const MobileView = ({ children }: Props) => {
    if (isTouchDevice()) return (<> {children} </>)
    return null;
}

const isBrowser = !isTouchDevice();

const isMobile = isTouchDevice();

function isTouchDevice(): boolean {
    const device = uaParser.getDevice();
    if (typeof window !== 'undefined') {
        const isPad = checkForIpad();
        return !!(device?.type && ['mobile', 'tablet', 'wearable'].includes(device.type)) || isPad;
    }
    return false;

}

function checkForIpad(): boolean {
    const browserName = uaParser.getBrowser()?.name?.toLowerCase();
    const isSafari = browserName === 'safari';
    const platform = uaParser.getOS()?.name?.toLowerCase();
    const isMacOS = platform === 'mac os' || platform === 'macos';
    return isSafari && isMacOS && window.navigator.maxTouchPoints > 0;
}

export { BrowserView, MobileView, isBrowser, isMobile };

/**
Note: ipda not detectable in sfari uisng user-agent sirng
 */