import Link from "next/link";
import BecomeAffilateLink from "../Common/BecomeAffilateLink";
import ExternalLinks from "../Common/ExternalLinks";
import { useState } from "react";
import { useOrgTheme } from "../../../context/ThemeContext";
import { useRouter } from "next/router";

const DesktopHeaderSQ = (props: any) => {
  const {
    isStateNewJersey,
    handleLogoClick,
    logoUrl,
    setMobileView,
    openMobileView,
    showGoToCurrentPurchaseButton,
    goToCurrentPurchase,
    signIn,
    hideCurrentPurchaseAndTrackYourPurchaseButtons,
  } = props;

  const { getOrgSettings, getTheme } = useOrgTheme();
  const { show_affialiate, external_links } = getOrgSettings();
  const route = useRouter();
  const { contactNumbers = [] } = getTheme();
  const contactNumber: any = contactNumbers[0];
  const [image, setImage] = useState("");

  function setMenuImage() {
    if (route.pathname === "/battery") {
      setImage("/images/menu/battery-img-drop-down.jpg");
    } else if (route.pathname === "/charger") {
      setImage("/images/menu/ev-charger-img-drop-down.jpg");
    } else {
      setImage("/images/menu/solar-img-drop-down.jpg");
    }
  }

  function setMenuImage2() {
    if (route.pathname === "/blog") {
      setImage("/images/menu/blog-menu.jpeg");
    } else if (route.pathname === "/resources") {
      setImage("/images/menu/resource-menu.jpg");
    } else {
      setImage("/images/menu/about-us-menu.jpeg");
    }
  }
  return (
    <header className="main-header">
      <nav
        className={` w-full shadow top-0 z-40 bg-white  px-4 md:px-8 xl:px-12 xxl:px-20 flex flex-row justify-between py-3 sm:py-5 2xl:py-6 flex-col sm:flex-row`}
      >
        <div className={` justify-between flex`}>
          {
            <Link href="/">
              <a className="logo-img z-100" onClick={handleLogoClick}>
                <img
                  src={`${logoUrl}`}
                  alt="Solar Quote"
                  className={`object-contain cursor-pointer spl__brandLogo w-52 md:w-60`}
                />
              </a>
            </Link>
          }
          {
            <svg
              className="sm:hidden w-7 h-7 text-bodyColor mt-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setMobileView(!openMobileView)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          }
        </div>

        <div className="hidden xl:block my-auto">
          <div className="flex items-center justify-center space-x-4 xl:space-x-4 xxl:space-x-6 2xl:space-x-8">
            <div className="dropdown inline-block relative">
              <span
                onMouseEnter={() => setMenuImage()}
                className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center cursor-pointer hover:shadow-none inline-flex items-center ${
                  route.pathname === "/solar" || route.pathname === "/battery"
                    ? "text-primary"
                    : "text-titleColor"
                }`}
              >
                <span className="mr-1">Products</span>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </span>
              <ul className="dropdown-menu absolute text-titleColor shadow-2xl rounded border border-gray-100 py-4 bg-white space-y-4">
                <div className="grid grid-cols-2 px-6">
                  <div className="py-2 pr-6">
                    <img src={image} className="object-contain solar" />
                  </div>
                  <div className="space-y-4 mt-2">
                    <li
                      onMouseEnter={() =>
                        setImage("/images/menu/solar-img-drop-down.jpg")
                      }
                      className="sub-menu solar-img-drop-down relative"
                    >
                      <Link href="/solar">
                        <a
                          className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium text-gray-500 hover:text-primary block whitespace-no-wrap ${
                            route.pathname === "/solar" ? "text-primary" : ""
                          }`}
                        >
                          <div>
                            <span
                              className={`block font-primaryMedium text-sm ${
                                route.pathname === "/solar"
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              Solar
                            </span>
                            <p className="text-sm text-bodyColor font-primaryRegular">
                              Customized solar to fit your needs
                            </p>
                          </div>
                        </a>
                      </Link>
                    </li>
                    <li
                      onMouseEnter={() =>
                        setImage("/images/menu/battery-img-drop-down.jpg")
                      }
                      className="sub-menu battery-img-drop-down relative"
                    >
                      <Link href="/battery">
                        <a
                          className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium text-gray-500 hover:text-primary block whitespace-no-wrap ${
                            route.pathname === "/battery" ? "text-primary" : ""
                          }`}
                        >
                          <div>
                            <span
                              className={`block font-primaryMedium text-sm ${
                                route.pathname === "/battery"
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              Battery
                            </span>
                            <p className="text-sm text-bodyColor font-primaryRegular">
                              Be prepared for emergencies with storage options.
                            </p>
                          </div>
                        </a>
                      </Link>
                    </li>
                    {/* <li onMouseEnter={() => setImage('images/menu/ev-charger-img-drop-down.jpg')}
                        className="sub-menu ev-charger-menu relative">
                        <Link href="/charger">
                          <a className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium hover:text-primary block whitespace-no-wrap ${route.pathname === '/charger' ? 'text-primary' : ''}`} >
                            <span className={`block ${route.pathname === '/charger' ? 'text-primary' : ''}`}>EV Chargers</span>
                            <p className="text-sm text-bodyColor font-primaryRegular">Don't drive to extinction, drive electric!
                              Keep your EV charged.</p>
                          </a>
                        </Link>
                      </li> */}
                  </div>
                </div>
              </ul>
            </div>
            {/* <Link
                href="/get-estimate">
                <a
                  className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer ${route.pathname==='/get-estimate'?'text-primary':'text-titleColor'}` }
              >
                Get An Estimate{" "}
              </a></Link> */}
            <Link href="/whySolar">
              <a
                className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center hover:text-primary hover:no-underline cursor-pointer ${
                  route.pathname === "/whySolar"
                    ? "text-primary"
                    : "text-titleColor"
                }`}
              >
                Why Solar{" "}
              </a>
            </Link>
            {/* <Link
              href="/companyPage"><a
                className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer ${route.pathname === '/companyPage' ? 'text-primary' : 'text-titleColor'}`}>
                Company{" "}
              </a></Link> */}

            <div className="dropdown inline-block relative">
              {/* <Link href="#"> */}
              <span
                onMouseEnter={() => setMenuImage2()}
                className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center cursor-pointer hover:shadow-none inline-flex items-center ${route.pathname === "/company" || route.pathname === "/blog" || route.pathname === "/resources" ? "text-primary": "text-titleColor"}`}
              >
                <span className="mr-1">Company</span>
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </span>
              {/* </Link> */}
              <ul className="dropdown-menu absolute smallMenu text-titleColor shadow-2xl rounded border border-gray-100 py-4 bg-white space-y-4">
                <div className="grid grid-cols-2 px-6">
                  <div className="py-2 pr-6">
                    <img src={image} className="object-contain solar" />
                  </div>
                  <div className="space-y-4 mt-2">
                    <li
                      onMouseEnter={() =>
                        setImage("/images/menu/about-us-menu.jpeg")
                      }
                      className="sub-menu company relative"
                    >
                      <Link href="/company">
                        <a
                          className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium text-gray-500 hover:text-primary block whitespace-no-wrap ${
                            route.pathname === "/company" ? "text-primary" : ""
                          }`}
                        >
                          <div>
                            <span
                              className={`block font-primaryMedium text-sm ${
                                route.pathname === "/company"
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              About us
                            </span>
                            {/* <p className="text-sm text-bodyColor font-primaryRegular">
                            Learn more about our mission and team behind our innovations
                            </p> */}
                          </div>
                        </a>
                      </Link>
                    </li>
                    <li
                      onMouseEnter={() =>
                        setImage("/images/menu/resource-menu.jpg")
                      }
                      className="sub-menu company relative"
                    >
                      <Link href="/resources">
                        <a
                          className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium text-gray-500 hover:text-primary block whitespace-no-wrap ${
                            route.pathname === "/resources"
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          <div>
                            <span
                              className={`block font-primaryMedium text-sm ${
                                route.pathname === "/resources"
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              Resources
                            </span>
                          </div>
                        </a>
                      </Link>
                    </li>
                    <li
                      onMouseEnter={() =>
                        setImage("/images/menu/blog-menu.jpeg")
                      }
                      className="sub-menu company relative"
                    >
                      <Link href="/blog">
                        <a
                          className={`cursor-pointer lg:text-base 2xl:text-lg font-primaryMedium text-gray-500 hover:text-primary block whitespace-no-wrap ${
                            route.pathname === "/blog" ? "text-primary" : ""
                          }`}
                        >
                          <div>
                            <span
                              className={`block font-primaryMedium text-sm ${
                                route.pathname === "/blog" ? "text-primary" : ""
                              }`}
                            >
                              Blog
                            </span>
                            {/* <p className="text-sm text-bodyColor font-primaryRegular">
                            Dive into expert articles and the latest trends in the solar industry
                            </p> */}
                          </div>
                        </a>
                      </Link>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
            <Link href="/support">
              <a
                className={`mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer ${
                  route.pathname === "/support"
                    ? "text-primary"
                    : "text-titleColor"
                }`}
              >
                Support
              </a>
            </Link>
            {show_affialiate && <BecomeAffilateLink />}
            {external_links && external_links.length > 1 ? (
              <div className="dropdown inline-block relative">
                <div className="mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center text-titleColor cursor-pointer hover:shadow-none inline-flex items-center">
                  <span className="mr-1">External Link</span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </div>
                <ul className="dropdown-menu absolute text-titleColor shadow-2xl rounded border border-gray-100 py-4 bg-white space-y-4 external-dropdown">
                  <div className="grid grid-cols-1 px-2">
                    <ExternalLinks
                      data={external_links}
                      className="py-2 px-3 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-left  hover:text-primary hover:no-underline cursor-pointer "
                    />
                  </div>
                </ul>
              </div>
            ) : (
              <ExternalLinks
                data={external_links}
                className="mt-2 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer"
              />
            )}

            {!hideCurrentPurchaseAndTrackYourPurchaseButtons && (
              <div className="my-auto">
                {showGoToCurrentPurchaseButton && (
                  <span
                    className="mt-4 md:mt-0 lg:text-base 2xl:text-lg font-primaryMedium text-center text-titleColor  hover:text-primary hover:no-underline cursor-pointer"
                    onClick={goToCurrentPurchase}
                  >
                    {" "}
                    Current purchase
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row items-end pt-2 sm:pt-0 space-x-4 xxs:space-x-4 2xl:space-x-6 justify-center xxs:items-center md:justify-center">
          {contactNumber && (
            <Link href={`tel:${contactNumber}`}>
              <a className="md:pt-0 mt-0 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium flex items-end sm:items-center justify-center text-titleColor">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                <span className="leading-tight mb-0.5 sm:mb-0">
                  {contactNumber}
                </span>
              </a>
            </Link>
          )}
          {!hideCurrentPurchaseAndTrackYourPurchaseButtons && (
            <span
              className={
                "flex items-center justify-center mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center cursor-pointer " +
                (isStateNewJersey ? "text-superSecondary" : "text-primary")
              }
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                ></path>
              </svg>
              <span className="mt-1 md:mt-0 leading-tight" onClick={signIn}>
                Track purchase
              </span>
            </span>
          )}
          <svg
            className="sm:block hidden xl:hidden w-7 h-7 text-bodyColor"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setMobileView(!openMobileView)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </div>
      </nav>
    </header>
  );
};
export default DesktopHeaderSQ;
