export function getContrastInfo(color1:string, color2:string) {
    // Function to convert color to relative luminance
    function getRelativeLuminance(color:string) {
      color = color.replace(/^#/, '');
      const num = parseInt(color, 16);
      const r = ((num >> 16) & 0xFF) / 255;
      const g = ((num >> 8) & 0xFF) / 255;
      const b = (num & 0xFF) / 255;
  
      const gammaCorrectedRGB = [r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4),
                                 g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4),
                                 b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)];
  
      return 0.2126 * gammaCorrectedRGB[0] + 0.7152 * gammaCorrectedRGB[1] + 0.0722 * gammaCorrectedRGB[2];
    }
  
    // Calculate contrast ratio
    const luminance1 = getRelativeLuminance(color1);
    const luminance2 = getRelativeLuminance(color2);
  
    const brighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);
  
    const contrastRatio = (brighter + 0.05) / (darker + 0.05);
  
    // Determine contrast level
    const contrastLevel = contrastRatio >= 7 ? 'AAA' : contrastRatio >= 4.5 ? 'AA' : 'Fail';
  
    // Determine contrast type (light or dark)
    const isLightContrast = luminance1 > luminance2;
  
    return {
      contrastRatio: contrastRatio.toFixed(2),
      contrastLevel: contrastLevel,
      contrastType: isLightContrast ? 'Light' : 'Dark'
    };
  }
  
function rgbToHsl(color: string): number[] {
    color = color.replace(/^#/, '');
    const num = parseInt(color, 16);
    const r = ((num >> 16) & 0xFF) / 255;
    const g = ((num >> 8) & 0xFF) / 255;
    const b = (num & 0xFF) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h:any, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        }

        h /= 6;
    }

    return [h, s, l];
}
  
  // Function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number): number[] {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [
        Math.round(r * 255),
        Math.round(g * 255),
        Math.round(b * 255)
    ];
}
  
  // Helper function to adjust contrast by modifying brightness
  function adjustContrast(color: string, factor: number): string {
    // Convert color to HSL
    const hsl = rgbToHsl(color);
  
    // Adjust lightness to increase or decrease contrast
    hsl[2] = hsl[2] < 0.5
      ? hsl[2] * (1 + factor)
      : hsl[2] + (1 - hsl[2]) * (factor - 1);
  
    // Convert back to RGB
    const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
  
    // Convert RGB to hex color
    const adjustedColor = `#${rgb[0].toString(16).padStart(2, '0')}${rgb[1].toString(16).padStart(2, '0')}${rgb[2].toString(16).padStart(2, '0')}`;
  
    return adjustedColor;
  }
  
  // Function to increase contrast
  export function increaseContrast(color: string, factor: number = 1.2): string {
    return adjustContrast(color, factor);
  }
  
  // Function to decrease contrast
  export function decreaseContrast(color: string, factor: number = 0.8): string {
    return adjustContrast(color, factor);
  }
  
  export function isColorDark(color:string) {
    const rgb = hexToRgb(color);
    const luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
    return luminance < 128;
  }
  
  export function isColorLight(color:string){
    return !isColorDark(color)
  }
  function hexToRgb(hex:string) {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    return { r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
  }
  
  interface RGB {
    r: number;
    g: number;
    b: number;
  }
  
  // Make a color darker by a specified percentage
  export function darkenColor(color: string, percentage: number=20): string {
    const factor = 1 - percentage / 100;
    const rgb: RGB = hexToRgb(color);
  
    rgb.r = Math.floor(rgb.r * factor);
    rgb.g = Math.floor(rgb.g * factor);
    rgb.b = Math.floor(rgb.b * factor);
  
    return rgbToHex(rgb);
  }
  
  // Make a color lighter by a specified percentage
  export function lightenColor(color: string, percentage: number = 20): string {
    const factor = 1 + percentage / 100;
    const rgb: RGB = hexToRgb(color);
  
    rgb.r = Math.min(Math.floor(rgb.r * factor), 255);
    rgb.g = Math.min(Math.floor(rgb.g * factor), 255);
    rgb.b = Math.min(Math.floor(rgb.b * factor), 255);
  
    return rgbToHex(rgb);
  }
  
  // Helper function to convert RGB to hex
  function rgbToHex(rgb: RGB): string {
    return `#${(1 << 24 | rgb.r << 16 | rgb.g << 8 | rgb.b).toString(16).slice(1)}`;
  }
  
  export function invertColor(color: string): string {
    const rgb: RGB = hexToRgb(color);
  
    // Invert each RGB component
    rgb.r = 255 - rgb.r;
    rgb.g = 255 - rgb.g;
    rgb.b = 255 - rgb.b;
  
    return rgbToHex(rgb);
  }
  