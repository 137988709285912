import { useRouter } from "next/router";
import { useOrgTheme } from "../../context/ThemeContext";
import dynamic from "next/dynamic";
import { isDynamicPage, isSQStaticPage } from "../../utils/page.utils";

const Loader = dynamic(() => import("../Common/GlobalLoader"), { ssr: false });
const NotFound = dynamic(() => import("../Common/NotFound"), { ssr: false });

const Page = (props: any) => {
  const { isThemeLoaded, hasRouteAccess } = useOrgTheme();
  const router = useRouter();

  const isServerside = typeof window === "undefined";

  if (!isServerside && !isThemeLoaded && (isDynamicPage(router.pathname) || isSQStaticPage(router.pathname))) {
    return <Loader />;
  }

  if (!isServerside && !hasRouteAccess()) {
    return <NotFound />;
  }

  return props.children;
};
export default Page;
