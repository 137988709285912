import React, { useState } from "react";
import "../styles/global.css";
import "../styles/custom.css";
import "../styles/custom-media-query.css";
import "../styles/estimate.css";
import { UserContext } from "../components/UserContext";
import Header from "../components/Header";
import { fetchEntries } from "../utils/contentfulBlogs";
import ReactModal from "react-modal";
import Modal from "react-modal";
import NewPruchaseAlertModal from "../components/NewPruchaseAlertModal";
import { useRouter } from "next/router";
import ThemeProvider from "./../context/ThemeProvider";
import Drift from "./../components/Common/Drift";
import Page from "./../components/Page";
import { GlobalProvider } from "./../context/GlobalContext";
import { LeadProvider } from "../context/LeadContext";
import GlobalLoader from "./../components/Common/GlobalLoader";
// This default export is required in a new `pages/_app.js` file
const InnerPages = [
  "/estimate",
  "/account",
  "/instant-estimate",
  "/instant-estimate-result",
  "/self-site-survey",
  "/estimate/[leadid]"
];

function MyApp({ Component, pageProps, landingPages, isStateNewJersey }) {
  const route = useRouter();

  const [userData, setUserData] = React.useState({});
  const [loader, setloader]=React.useState(false);
  const [headerShow, setHeaderShow] = React.useState(true);
  const [
    hideCurrentPurchaseAndTrackYourPurchaseButtons,
    setHideCurrentPurchaseAndTrackYourPurchaseButtons,
  ] = React.useState(false);

  const [openNewProjectAlertModal, setOpenNewProjectAlertModal] =
    React.useState(false);

  const setData = async (data) => {
    if (data != undefined) {
      // localStorage.setItem('leadData', JSON.stringify(data))
      await setUserData(data);
    }
  };
  React.useEffect(() => {
    if (isStateNewJersey) {
      document?.documentElement?.style?.setProperty("--primary", "#5caade");
      document.documentElement.style.setProperty("--secondary", "#e67d28");
      document.documentElement.style.setProperty("--superSecondary", "#9aca3d");
    }
    const data = JSON.parse(localStorage.getItem("leadData") || "{}");
    setUserData(data);
  }, []);

  React.useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // LocalStorage update with new lead need to refresh
    window.addEventListener(
      "storage",
      async function (event) {
        if (event.storageArea === localStorage) {
          let userData;
          await setUserData((currentValue) => {
            userData = currentValue;
            return currentValue;
          });
          if (
            userData &&
            Object.keys(userData).length &&
            event.storageArea.leadData
          ) {
            const leadData = JSON.parse(event.storageArea.leadData);
            // TO_DO: dynaice
            if (
              route.pathname.includes("/proposal/") ||
              event.url.includes("/proposal/")
            ) {
              return;
            }
            // const eventURL = new URL(event.url);
            if (
              !InnerPages.includes(route.pathname)
              // !InnerPages.includes(eventURL.pathname)
            ) {
              return;
            }
            if (
              (userData.id && leadData.id && userData.id !== leadData.id) ||
              (!userData.instantEstimate && leadData.instantEstimate) ||
              (userData.instantEstimate && !leadData.instantEstimate) ||
              (userData.instantEstimate &&
                leadData.instantEstimate &&
                userData.full_address === leadData.full_address &&
                userData.avg_monthly_bill === leadData.avg_monthly_bill &&
                userData.utility_name === leadData.utility_name)
            ) {
              setUserData({});
              setOpenNewProjectAlertModal(true);
            }
          }
        }
      },
      false
    );
  }, []);

  React.useEffect(() => {
    const { pathname } = route;
    if (
      pathname === "/estimate" ||
      pathname === "/instant-estimate" ||
      pathname.includes("/proposal/")
    ) {
      document.body.classList.add("hide-drift");
    } else {
      document.body.classList.remove("hide-drift");
    }

    if (!headerShow && (route.pathname === "/")) {
      setHeaderShow(true);
    }

    () => {
      document.body.classList.remove("hide-drift");
      // return handleScroll();
    };
  }, [route.pathname]);

  const onRefresh = () => {
    setOpenNewProjectAlertModal(false);
    if(route.pathname === "/estimate/[leadid]"){
      window.location.replace("/estimate")  
    }else{
      window.location.reload();
    }
  };

  const onCloseTab = () => {
    setOpenNewProjectAlertModal(false);
  };

  ReactModal.setAppElement("body");

  return (
    <div>
      <Modal
        preventScroll={false}
        isOpen={openNewProjectAlertModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => setOpenNewProjectAlertModal(false)}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay leadformModal"
        }
        ariaHideApp={false}
      >
        <NewPruchaseAlertModal onRefresh={onRefresh} onCloseTab={onCloseTab} />
      </Modal>

      <ThemeProvider>
        <GlobalProvider>
          {<Drift />}
          {/* {<HiddenComponent />} */}
          <UserContext.Provider
            value={{
              userData: userData,
              setUserData: setData,
              setloader: setloader,
              isStateNewJersey: isStateNewJersey,
              setHeaderShow: setHeaderShow,
              setHideCurrentPurchaseAndTrackYourPurchaseButtons:
                setHideCurrentPurchaseAndTrackYourPurchaseButtons,
            }}
          >
            <LeadProvider lead={{}}>
              <Page>
                {headerShow && (
                  <Header
                    hideCurrentPurchaseAndTrackYourPurchaseButtons={
                      hideCurrentPurchaseAndTrackYourPurchaseButtons
                    }
                    landingPages={landingPages}
                  />
                )}
                <Component {...pageProps} />
              </Page>
              {loader && <GlobalLoader />}
            </LeadProvider>
          </UserContext.Provider>
        </GlobalProvider>
      </ThemeProvider>
      {/* New Pruchase Alert starts Modal */}
    </div>
  );
}

MyApp.getInitialProps=async (ctx) => {
  console.log("initial");
  const res = await fetchEntries({ content_type: "landingPages" });
  const landingPages = await res.map((p) => {
    return {
      ...p.fields,
      createdAt: p.sys.createdAt,
      updatedAt: p.sys.updatedAt,
    };
  });

  let isStateNewJersey = false;
  const region = "NJ";
  const regionName = "New Jersey";

  try {
    const response = await fetch(
      `https://pro.ip-api.com/json/?fields=status,message,countryCode,regionName,region&key=I1zx8NeiBkptFHi`
    );
    const data = await response.json();
    if (
      data.status === "success" &&
      data.region?.toLowerCase() === region.toLowerCase() &&
      data.regionName?.toLowerCase() === regionName.toLowerCase()
    )
      isStateNewJersey = true;
  } catch (err) {
    console.log("error for new jersy", err);
  }
  return {
    landingPages,
    isStateNewJersey,
  };
};

export default MyApp;
