import { ReactNode, createContext, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { Lead } from "../interfaces/lead.interface";
import { Proposal } from "../interfaces/lead.interface";
import { setResetLoan } from "../utils/common.function.utils";
import { ProviderConnectedStatus } from "../services/loan.service";
import { CONTRACT_TYPE } from "../enums/contractType.enum";

interface Action {
  type: LEAD_ACTION_TYPE
  payload?: Partial<LeadContext | Proposal>;
}

export enum LEAD_ACTION_TYPE {
  UPDATE_LEAD = 'UPDATE_LEAD',
  UPDATE_PROPOSAL = 'UPDATE_PROPOSAL',
  RESER_LEAD = 'RESET_LEAD'
}

interface LeadContext extends Lead {
  financeProviderStatus?: ProviderConnectedStatus;
}


const LeadContext = createContext<LeadContext | undefined>(undefined);
const LeadDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);


export function LeadProvider({ children, lead }: { children: ReactNode; lead: LeadContext; }) {
  const [state, dispatch] = useReducer(leadReducer, lead);
  const prevProposalRef = useRef(state?.proposal);

  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, [dispatch]);

  useEffect(() => {
    const { proposal } = memoizedState;
    const preProposalVal = prevProposalRef.current;
    const contract_type = proposal?.contract_type;

    if (!!contract_type && !!proposal?.loan_filter && preProposalVal) {
      const hasNetAmountChnaged = +preProposalVal?.net_amount !== +proposal.net_amount;
      if (hasNetAmountChnaged && contract_type === CONTRACT_TYPE.LOAN) {
        setResetLoan();
      }
    }
    prevProposalRef.current = proposal;
  }, [memoizedState?.proposal]);

  return (
    <LeadContext.Provider value={memoizedState}>
      <LeadDispatchContext.Provider value={memoizedDispatch}>
        {children}
      </LeadDispatchContext.Provider>
    </LeadContext.Provider>
  );
}

function leadReducer(state: LeadContext, action: Action): LeadContext {
  switch (action.type) {
    case LEAD_ACTION_TYPE.UPDATE_LEAD:
      return { ...state, ...action.payload } as Lead;
    case LEAD_ACTION_TYPE.UPDATE_PROPOSAL:
      const proposal = { ...state.proposal, ...action.payload as Proposal };
      return { ...state, proposal };
    case LEAD_ACTION_TYPE.RESER_LEAD:
      return {} as Lead;
  }
}

export function useLeadContext() {
  const context = useContext(LeadContext);
  if (context === undefined) {
    throw new Error('useLeadContext must be used within a LeadProvider');
  }
  return context;
}

export function useLeadDispatchContext() {
  const context = useContext(LeadDispatchContext);
  if (context === undefined) {
    throw new Error('useLeadDispatchContext must be used within a LeadProvider');
  }
  return context;
}

export function useProposalContext() {
  const context = useContext(LeadContext);
  if (context === undefined) {
    throw new Error('useProposalContext must be used within a LeadProvider');
  }
  return context.proposal;
}
