import { Lead } from "../interfaces/lead.interface";
import callAPI from "../utils/callAPI";
import axios, {AxiosError ,AxiosResponse} from "axios";
import { getLeadID } from "../utils/common.function.utils";
import { STEP } from "../enums/step.enum";

export default class LeadService {

  apiPrefix: string = `lead`;
  

  async createLead(payload: Partial<Lead>) {
    try {
      const config = {
        method: "POST",
        url: `${this.apiPrefix}`,
        data: payload
      };
      const response: AxiosResponse = await callAPI(config);
      return response.data;
    } catch (err) {
        this.handleError(err)
    }
    
  }

  async createInstantLead(payload: any) {
    try {
      const config = {
        method: "POST",
        url: `${this.apiPrefix}/instant`,
        data: payload
      };
      const response: AxiosResponse = await callAPI(config);
      return response.data;
    } catch (e) {
      this.handleError(e)
    }
    
  }

  async getLead(leadid?:number): Promise<Lead> {
    try {
      const id = leadid || getLeadID();
      const config = {
        method: "GET",
        url: `${this.apiPrefix}/${id}`,
      };
      const response: AxiosResponse = await callAPI(config);
      return response.data.data;
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  }

  async updateLead(payload:any){
    try {
      const id = getLeadID();
      const config = {
        method: "patch",
        url: `${this.apiPrefix}/${id}`,
        data:payload
      };
      const response: AxiosResponse = await callAPI(config);
      return response.data.data;
    } catch (err) {
      this.handleError(err);
    }
  }
  //TODO: interaces for payloads

  async updateSinglePageLead(payload:any){
    try {
      const id = getLeadID();
      const config = {
        method: "patch",
        url: `${this.apiPrefix}/update-single-page-lead/${id}`,
        data:payload
      };
      const response: AxiosResponse = await callAPI(config);
      return response.data.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  async sendOtp(payload: any) {
    try {
      const config = {
        method: "POST",
        url: `${this.apiPrefix}/send-otp`,
        data: payload
      };
      const res = await callAPI(config);
      return res.data;
    } catch (e) {
      this.handleError(e);
    }
   
  }

  async verifyOtp(payload: any) {
    try {
      const config = {
        method: "POST",
        url: `${this.apiPrefix}/verify-otp`,
        data: payload
      };
      const res = await callAPI(config);
      return res.data;
    } catch (e) {
      this.handleError(e);
    }
  }

  async updateCurrentStep(payload: { lead_id: number; current_step: STEP; }) {
    try {
      const config = {
        method: "post",
        url: `${this.apiPrefix}/update-current-step`,
        data:payload
      }

      const response = await callAPI(config);
      return response;
    } catch (err) {
      this.handleError(err);
    }
  }

  async convertLeadToJob() {
    try {
      const leadID = getLeadID();
      const config = {
        method: "post",
        url: `${this.apiPrefix}/convert/${leadID}`,
      }

      const response = await callAPI(config);
      return response.data.data;
    } catch (err) {
      this.handleError(err);
      throw err
    }
    
  }

  private handleError(err: AxiosError|any) {
    let error = "Something went wrong";
    if (axios.isAxiosError(err)) {
      const axiosErr = (err as AxiosError< any>)
      const response = axiosErr.response?.data;
      if (Array.isArray(response.errors)) {
        error = response.errors[0] as string;
      } else if(response?.Error){ 
        error = response.Error;
      } else if (response?.message) {
        error = response.message;
      } else {
        error = err.message;
      }
        
    }
    throw new Error(error);
  }
}