

import { ContentfulClientApi, createClient } from 'contentful';


export const fetchEntries = async (query: any = {}) => {
    const space: any = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
    const accessToken: any = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;

    const client: ContentfulClientApi = createClient({
        space: space,
        accessToken: accessToken
    });
    const entries = await client.getEntries(query);
    if (entries.items)
        return entries.items
    else
        return [];
}