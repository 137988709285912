import randomString from "randomstring";
import { EQUIPMENTS } from "../enums/equipments.enum";

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function formatDateOnly(date: string): string {
  const options = {
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

export function formatTimeOnly(date: string): string {
  const options = {
    hour: "numeric" as const,
    minute: "numeric" as const,
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

export function formatDateTime(date: string): string {
  const options = {
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
    hour: "numeric" as const,
    minute: "numeric" as const,
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

export function generateRandomString(): string {
  return randomString.generate({
    length: 8,
    charset: "alphanumeric",
  });
}

//TO:DO types based on instant estimate page
export function getLeadID() {
  const leadData = getLeadData();
  return leadData?.id;
}

export function setLeadData(lead: any) {
  if (!!lead?.proposal) {
    delete lead.proposal;
  }

  localStorage.setItem("leadData", JSON.stringify(lead || ""));
}

export function getLeadData() {
  const leadJSON = localStorage.getItem("leadData");
  if (leadJSON) {
    return JSON.parse(leadJSON);
  }
  return null;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getURLParams() {
  // Get the URL parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Convert parameters to an object
  const paramsObject: any = {};
  urlParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
  // Store the object in localStorage
}
export function setHomePageQeryParams(params: any) {
  localStorage.setItem("homeQueryParams", JSON.stringify(params));
}
export function getHomePageQeryParams() {
  const params = localStorage.getItem("homeQueryParams");
  if (params) {
    return JSON.parse(params);
  }
  return {};
}
export function getFromURL() {
  const baseUrl = window.location.origin;
  const url = new URL(baseUrl);
  const params = getHomePageQeryParams();

  // Append parameters to the URL
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  return url.href;
}

export function storFromURLParams() {
  const params = getURLParams();
  setHomePageQeryParams(params);
}

export function setResetLoan(val: boolean = true) {
  if (!val) {
    removeKeyFromLocalStorage("refresh");
    return;
  }
  localStorage.setItem("refresh", `${val}`);
}

export function isLoanReset() {
  return !!JSON.parse(localStorage.getItem("refresh") || "null");
}

export function setDesignUpdateAlert(val: boolean = true) {
  if (!val) {
    removeKeyFromLocalStorage("alertForDesign");
    return;
  }
  localStorage.setItem("alertForDesign", `${val}`);
}

export function getDesignUpdateAlert() {
  return !!JSON.parse(localStorage.getItem("alertForDesign") || "null");
}

export function setDesignUUID(designUUID: string | null) {
  if (!designUUID) {
    removeKeyFromLocalStorage("design_uuid");
    return;
  }
  localStorage.setItem("design_uuid", `${designUUID}`);
}

export function getDesignUUID() {
  return localStorage.getItem("design_uuid");
}

export function removeKeyFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function setSelectionConfirmed(val: boolean = true) {
  if (!val) {
    removeKeyFromLocalStorage("confirmedSelection");
    return;
  }
  localStorage.setItem("confirmedSelection", `${val}`);
}

export function getSelectionConfirmed() {
  return !!JSON.parse(localStorage.getItem("confirmedSelection") || "false");
}

export function setBatteryPopup(val: boolean = true) {
  if (!val) {
    removeKeyFromLocalStorage("wantBattery");
    return;
  }
  localStorage.setItem("wantBattery", `${val}`);
}

export function hasBatteryPopupShownOnce() {
  return !!JSON.parse(localStorage.getItem("wantBattery") || "false");
}

export function setNavigator(val: number) {
  localStorage.setItem("dir", `${val}`);
}

export function getNavigator() {
  return +(localStorage.getItem("dir") || 0);
}

export function setDesignUpdate(val = true) {
  if (!val) {
    removeKeyFromLocalStorage("designUpdate");
    return;
  }
  localStorage.setItem("designUpdate", `${val}`);
}

export function hasDesignUpdated() {
  return !!JSON.parse(localStorage.getItem("designUpdate") || "false");
}
// TO_DO: use better way to hanle localStorage interaction

interface keysInterface {
  label: string | ((data?: any) => any);
  value: string;
  index?: boolean;
}
export const createSelectOptions = (data: Array<{}>, keys: keysInterface) => {
  const { label, value, index } = keys;

  return data?.map((option: any, i) => {
    const opt: any = {
      value: option[value],
    };
    let labelValue = "";
    if (typeof label === "function") {
      labelValue = label(option);
    } else {
      labelValue = option[label];
    }
    opt.label = labelValue;
    index && (opt.index = i);
    return opt;
  });
};

export const getEquipments = () => {
  let equipments: any = localStorage.getItem("equipments");
  if (equipments != null) {
    equipments = JSON.parse(equipments);
  }
  return equipments || {};
};
const setEquipments = (equipments: any) => {
  return localStorage.setItem("equipments", JSON.stringify(equipments));
};
export const storeEquipmentLength = (eqp: EQUIPMENTS, len: number) => {
  const equipments: any = getEquipments();
  equipments[eqp] = len;
  setEquipments(equipments);
};
export const getEquipmentLength = (eqp: EQUIPMENTS) => {
  const equipments: any = getEquipments();
  return equipments[eqp] || 0;
};
export const getEquipmentsLength = getEquipments;

export function isNumber(s:string) {
  return /^-?\d+(\.\d+)?$/.test(s);
}

export function isNull(data:any) {
  return data===null;
}

export const removeUndefineds = (data: any): any => {
  try{
    if (Array.isArray(data)) {
      return data.map(item => removeUndefineds(item));
    } else if (data !== null && typeof data === 'object') {
      const result: any = {};
      for (const [key, value] of Object.entries(data)) {
        if (value !== undefined) {
          result[key] = removeUndefineds(value);
        }
      }
      return result;
    }
    return data;
  }catch(err){
    console.log("Error in removeUndefineds",err);
  }
  return data;
};
