var CryptoJS = require("crypto-js");

const decrypt = (encryptedValue:string)=>{
  try{
    var encrypted:any = encryptedValue;
    var key = process.env.DECRYPTING_KEY;
   
    encrypted = atob(encrypted);
   
    encrypted = JSON.parse(encrypted);
    
    var iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    var value = encrypted.value;
    key = CryptoJS.enc.Base64.parse(key);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted
  }catch(e:any){
    console.error(e)
    console.log(e.message)
  }
    // pk_test_51GxKWrDDibTjdVXGqvjm45tNPWt6H0Il6Q72kWY3ScYii7qm9I3T6Bu4cOMwIWhC1gwkP0vwXbmTbwu5yXdxipKG00qOiFen1o
}
export default decrypt