import React, { useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";

import { UserContext } from "../UserContext";
import ReferralLoginModal from "./refrerralLoginModal";
import ReferralForgotPasswordModal from "./referralForgotPasswordModal";
import ReferralSetPasswordModal from "./referralSetPasswordModal";
import Layout from "../Layout";
import { getMultiTenentMeta } from "../../utils/meta";
import { AffiliateContent, AffiliateRoutes } from "../../pages/ambassador";
import ThemeContext from "../../context/ThemeContext";
import ReminderPopup from "./ReminderPopup";
import Image from "next/image";
import dynamic from "next/dynamic";
import Spinner from "../Common/Spinner";

type ModalType = "login" | "setPassword" | "forgotPassword";
interface AffiliateProps {
  modalType?: ModalType;
  content?: AffiliateContent;
}

const ClientAffiliate = dynamic(() => import("./Client"), {
  ssr: false,
  loading: Spinner,
});
const SolarAffiliate = dynamic(() => import("./SolarAffiliate"), {
  ssr: false,
  loading: Spinner,
});

const Affiliate = (props: AffiliateProps) => {

  const { getLogoUrl, isClientOrg } = useContext(ThemeContext);

  // const [content,setContent] = useState<any>(null)
  const { content } = props;
  // const show_energy_advisor = false;
  const logoUrl = getLogoUrl();

  const isClient = isClientOrg || content?.isClient;
  const AffiliateMetaTagsData = useMemo(
    () => getMultiTenentMeta(content?.orgName || "", "affiliate"),
    [content?.orgName]
  );

  const router = useRouter();
  const intitModal = {
    login: false,
    setPassword: false,
    forgotPassword: false,
  };

  const { setloader } = useContext(UserContext);
  const [modal, setModal] = useState<{ [key in ModalType]: boolean }>({
    ...intitModal,
  });

  useEffect(() => {
    setloader(false);
    if (props?.modalType) {
      setModal(() => ({ ...intitModal, [props.modalType as string]: true }));
    }
  }, [props.modalType]);

  function handleModalClose() {
    setModal(intitModal);
    router.push({ pathname: AffiliateRoutes.index });
  }
  return (
    <Layout
      title={AffiliateMetaTagsData.title}
      description={AffiliateMetaTagsData.description}
      keywords={AffiliateMetaTagsData.keywords}
    >
      {/* Login */}
      <Modal
        preventScroll={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog optimizeModal"
        }
        ariaHideApp={false}
        isOpen={modal.login}
        onRequestClose={handleModalClose}
        // style={customStyles}
        contentLabel="Example Modal"
      >
        <ReferralLoginModal closeLogin={handleModalClose}></ReferralLoginModal>
      </Modal>
      {/* Forgot Password */}
      <Modal
        preventScroll={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog optimizeModal"
        }
        ariaHideApp={false}
        isOpen={modal.forgotPassword}
        onRequestClose={handleModalClose}
        // style={customStyles}
        contentLabel="Example Modal"
      >
        <ReferralForgotPasswordModal
          closeForgotPasswordModal={handleModalClose}
        ></ReferralForgotPasswordModal>
      </Modal>
      <Modal
        preventScroll={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={
          "EstimateDetailModal__Overlay form-control-overlay otp-dialog optimizeModal"
        }
        ariaHideApp={false}
        isOpen={modal.setPassword}
        onRequestClose={handleModalClose}
        // style={customStyles}
        contentLabel="Example Modal"
      >
        <ReferralSetPasswordModal
          closeSetPassword={handleModalClose}
        ></ReferralSetPasswordModal>
      </Modal>
      <ReminderPopup />
      <div className={`${isClient ? "flex flex-col h-screen" : ""}`}>
        <header className="px-4 sticky top-0 w-full z-50 jj">
          <div className="px-0 py-5 md:p-5 md:pl-12 container m-auto xl:pl-16 xd:pl-0">
            <div className="grid grid-cols-2 justify-between">
              {logoUrl && (
                <div className="flex">
                  <Link href="/">
                    <a className="mb-2 sm:mb-0">
                      <Image
                        alt="logo"
                        src={logoUrl}
                        width="256px"
                        height="47px"
                        className={`object-contain cursor-pointer spl__brandLogo ${
                          isClient ? "customLogoWidth" : "w-52 md:w-60"
                        } `}
                      />
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>
        {isClient ? (
          <ClientAffiliate affiliateMetaContent={content as AffiliateContent} />
        ) : (
          <SolarAffiliate />
        )}
        <ToastContainer position="bottom-center" autoClose={1000} />
      </div>
    </Layout>
  );
};
export default Affiliate;
