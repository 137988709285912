const GlobalLoader = () => {
  return (
    <div
      className="flex items-center justify-center z-99999 relative global-loader"
    >
      {/* <Image src="/images/logo animation.gif" className="h-16"/> */}
      {/* DON'T REMOVE CLASS hidden */}
      <img src="/images/logo animation.gif" className="hidden h-16"/>
      {/* <div className="spinner"></div> */}
    </div>
  );
};
export default GlobalLoader