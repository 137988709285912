// import ReactWOW from "react-wow";
import "animate.css";
import Affiliate from "../../components/Affiliate";
import { NextPageContext } from "next";
import { getPageContent } from "../../utils/contentFetcher";
import { useEffect, useMemo } from "react";
import { useOrgTheme } from "../../context/ThemeContext";
import { OrganisationTheme } from "../../interfaces/Theme";

export const AffiliateRoutes = {
  index: "/ambassador",
  login: "/ambassador/login",
  forgotPassword: "/ambassador/forgotPassword",
  setPassword: "/ambassador/resetPassword",
};

export type AffiliateContent = Pick<OrganisationTheme, "affiliatePageContent" | "is_preview" | "hostNameId" | "orgId" | "orgName"> & {
  isClient: boolean;
  cssVariables: OrganisationTheme["cssVariables"];
};

const AffiliatePage = ({ content }: { content: AffiliateContent }) => {
  const { sethostname } = useOrgTheme();

  const affiliateContent = useMemo(() => content , [content]);

  useEffect(() => {
    sethostname({
      id: affiliateContent.hostNameId,
      orgId: affiliateContent.orgId,
      preview: affiliateContent.is_preview,
    });
  }, [affiliateContent]);

  return <Affiliate content={affiliateContent}></Affiliate>;
};

export default AffiliatePage;

export async function getServerSideProps(ctx: NextPageContext) {
  const res = await getPageContent("affiliate", ctx);
  console.log(res.data);
  return {
    props: {
      content: res.data,
    },
  };
}
