import React, { useContext, createContext, useState } from "react";

const GlobalContext = createContext<any>(null);
declare global {
  interface Window {
    drift?: any;
    LiveChatWidget?: any;
    $_Tawk?: any;
    leadConnector?:any;
  }
}

export const GlobalProvider = (props: any) => {
  const { children } = props;
  const [data, setData] = useState({});

  const isDrift = () => {
    return !!(window?.drift || window?.LiveChatWidget || window?.$_Tawk);
  };

  const showDrift = () => {
    try {
      window?.drift?.show();
      window?.$_Tawk?.showWidget();
      window?.leadConnector?.chatWidget.openWidget();
      document.body.classList.remove("hide-drift");
    } catch (e) {
      console.log(e);
    }
  };

  const hideDrift = () => {
    // window?.drift?.hide()
    try {
      window?.$_Tawk?.hideWidget();
      document.body.classList.add("hide-drift");
    } catch (e) {
      console.log(e);
    }
  };

  const addDriftClass = () => {
    document.body.classList.add("hide-drift");
  };
  const removeDriftClass = () => {
    document.body.classList.remove("hide-drift");
  };

  const setGlobalData = (data: {}) => {
    setData((prev: any) => ({ ...prev, ...data }));
  };

  const value = {
    hideDrift,
    showDrift,
    isDrift,
    removeDriftClass,
    addDriftClass,
    globalData: data,
    setGlobalData
    //getAbasdorPageData,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (context === undefined || !Object.keys(context || {})?.length) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
}
export default GlobalContext;
