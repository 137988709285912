import ExternalLinks from "../Common/ExternalLinks";
import { useOrgTheme } from "../../../context/ThemeContext";
import BecomeAffilateLink from "../Common/BecomeAffilateLink";
const MobileHeader = () => {
  const { getOrgSettings } = useOrgTheme();
  const { show_affialiate, external_links } = getOrgSettings();
  return (
    <nav className="px-4 grid gap-4 relative z-100">
      <ExternalLinks
        data={external_links}
        className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"
      />
      {show_affialiate && <BecomeAffilateLink  className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"/>}
    </nav>
  );
};
export default MobileHeader;
