import axios, { CancelTokenSource } from "axios";
const BASE_URL = process.env.API_URL;

export const API_URL = process.env.API_URL;

interface HTTPRequest {

    method?: string; // I've added other common HTTP methods. Adjust as needed.
    baseUrl?:string;
    url: string;
    data?: Record<string, any>; // This allows for an object with any string keys and values of any type.
    headers?: Record<string, string>; // Assuming headers will be key-value pairs with string values.
    params?: Record<string, any>; // Similarly, for params.
    source?: null | any; // 'any' if you're not sure about the structure of cancelToken, otherwise replace 'any' with the appropriate type.
    queryParams?: { [key: string]: any; };

}

function getComomonHeaders() {
  return {
    Accept: "application/json",
  };
}
export function cancelRequest(source: CancelTokenSource | undefined) {
  if (source) {
    source.cancel('Operation canceled by the user.'); // Cancel the request using the CancelTokenSource
    return axios.CancelToken.source(); // Create a new CancelTokenSource
  }
}
export default function callAPI(request: HTTPRequest) {

  // const BASE_URL = process.env.API_URL;
  const { method = "get", url = "", data = {}, headers = {},params,source=null,baseUrl=BASE_URL} = request;
  // let source = axios.CancelToken.source();
  // console.log(source)
 
  return axios({
    method,
    // url: `${BASE_URL}${url}`,
    url: baseUrl + url,

    data,
    params,
    headers: {
      ...getComomonHeaders(),
      ...headers,
    },
    cancelToken:source?.token
  });
}
