import { useEffect, useState } from "react";

export function usePersistedState(defaultValue: any, key: string) {
  const [value, setValue] = useState(() => {
    const stickyValue = typeof window !== 'undefined' ? localStorage.getItem(key): null;
    return stickyValue !== null && stickyValue !== 'undefined'
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  useEffect(() => {
    if(typeof window === 'undefined'){
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}