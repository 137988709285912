import { NextPageContext } from "next";

export type Page = "affiliate" | "referral" | "home";

let cache = new Map();

export async function getPageContent(pageName: Page, ctx: NextPageContext) {
  let domain = ctx.req?.headers.host;
  domain = getCurrentDomain() || domain;
  const isFullReload = !ctx.req?.headers.referer;

  const key = `${pageName}-${domain}`;

  if (isFullReload) {
    cache.delete(key);
  }

  // if (cache.has(key)) {
  //   return cache.get(key);
  // }

  const url = `${process.env.API_URL}get-page-content?domain=${domain}&page=${pageName}`;

  const res = await fetch(url);
  const response = await res.json();
  cache.set(key, response);
  return response;
}

export function getCurrentDomain(): string | null {
  let domain = typeof window !== "undefined" ? window.location.host : null;

  //Note:  in devlopment mode should assign a domain based on server
  if (process.env.NODE_ENV === "development") {
    // domain = "testing-solar-quote.vercel.app";
    // domain = "testing-suntuity.vercel.app"

  // domain = "sandbox.suntiuty.solarquote.com";
    domain = "staging.solarquote.com";
  }

  return domain;
}
