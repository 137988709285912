//Note: Tempropry need to remove once fxd from API


import { BANNER_LAYOUT } from "../components/Home/Client/Banner";
import { LEAD_FORM_POPUP } from "../components/LeadFormModal";


export const DEFAULT_ORG_SETTING = {
    self_site_survey_enabled: true,
    discount_amount: 200,
    federal_tax_credit: 30,
    dtc_limited_tool: {
      solar: {
        lead_source: null,
        sub_source: null
      },
      roof: {
        lead_source: null,
        sub_source: null
      }
    },
    dtc_full_tool: {
      solar: {
        lead_source: null,
        sub_source: null
      },
      roof: {
        lead_source: null,
        sub_source: null
      }
    },
    is_phone_number_required: true,
    lead_form_layout: LEAD_FORM_POPUP.CENTER_POPUP,
    show_initial_lead_form: true,
}

export const LAYOUT_DATA = {

  [BANNER_LAYOUT.LAYOUT_1]: {
    content: "<h3 class=\"ql-align-center\">Experience The Future Of Solar:</h3><h1 class=\"ql-align-center\">Buying Online has never been Simpler with our&nbsp;<span style=\"color: rgb(255,147,43);\">AI-Powered Tool</span></h1><p class=\"ql-align-center\">Purchase online with Solar Quote's&nbsp;<strong>AI-powered platform</strong>&nbsp;today and take advantage of our&nbsp;<strong>UNBEATABLE</strong>&nbsp;prices. We are committed to making sustainable power&nbsp;<u>accessible to all!</u></p>",
    formBtnLabel: "See my design",
    backgroundImage: "./images/home/bg-home-banner.jpeg",
    type: BANNER_LAYOUT.LAYOUT_1
  },

  [BANNER_LAYOUT.LAYOUT_2]: {
    content:"<h1 class=\"ql-align-center\">RESIDENTIAL SOLAR<span style=\"color: rgb(255,147,43);\"> MADE EASY!</span></h1><p>Order now for the lowest prices,<strong style=\"color: rgb(41, 66, 96);\">Guaranteed*</strong></p>",
    formBtnLabel:  "See my design",
    backgroundImage: "./images/landing-img/suntuity/banner-2.png",
    formDisclaimer: `<p>*Please see terms and conditions related to our Price Match Guarantee <a href=\"https://suntuityrenewables.com/dtc-price-match-guarantee/\" rel=\"noopener noreferrer\" target=\"_blank\">here</a>.</p>`,
    type: BANNER_LAYOUT.LAYOUT_2
  },

  [BANNER_LAYOUT.LAYOUT_3]: {
    content: "<h3>Experience The Ease Of&nbsp;</h3><h1>Solar Purchasing Online</h1><ul><li>Design Your Customized Energy System.</li><li>Discover Unmatched Solar Pricing.</li><li>Unlock Exceptional Energy Savings!</li></ul>",
    backgroundImage:"./images/sq-banner-img-vivint.jpg",
    formHeading: "Customize Your Solar Design",
    formDescription: "Start here to build a system for your specific home:",
    formBtnLabel: "See Recommendation",
    type: BANNER_LAYOUT.LAYOUT_3
  }
}

export const HOME_PAGE_CONTENT = {
  ...LAYOUT_DATA[BANNER_LAYOUT.LAYOUT_3],
}


