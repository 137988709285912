import dynamic from "next/dynamic";
import { useOrgTheme } from "../../../../context/ThemeContext";
import { OrganisationTheme } from "../../../../interfaces/Theme";

const LayoutOne = dynamic(() => import("./Layouts/Layout-1"));
const LayoutTwo = dynamic(() => import("./Layouts/Layout-2"));
const LayoutThree = dynamic(() => import("./Layouts/Layout-3"));

export enum BANNER_LAYOUT {
  LAYOUT_1 = "layout1", // ex: SQ
  LAYOUT_2 = "layout2", // ex: suntuty
  LAYOUT_3 = "layout3", // ex: other clients
}
type BannerContent = OrganisationTheme["pageContent"]["homepageContent"];
const ClientBanner = ({ content }: { content: BannerContent }) => {
  const { getHomeLayoutContent } = useOrgTheme();
  const layoutData = getHomeLayoutContent(content);
  const layout = layoutData.type;

  if (layout === BANNER_LAYOUT.LAYOUT_1) {
    return <LayoutOne data={layoutData} />;
  }

  if (layout === BANNER_LAYOUT.LAYOUT_2) {
    return <LayoutTwo data={layoutData} />;
  }

  return <LayoutThree data={layoutData} />;
};

export default ClientBanner;
