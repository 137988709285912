import Link from "next/link";
import { AffiliateRoutes } from "../../../pages/ambassador";

const BecomeAffilateLink = (props: any) => {
  const defaultClassName = "mt-2 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer text-titleColor"
  const { className = defaultClassName } = props;
  return (
    <Link href={AffiliateRoutes.index}>
      <a
        className={` ${className}`}
      >
        Become An Ambassador
      </a>
    </Link>
  );
};
export default BecomeAffilateLink;
