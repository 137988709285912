import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../components/UserContext";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { useOrgTheme } from "../context/ThemeContext";
import { EmailForm, PhoneForm } from "./OtpForms";
import { JOB_TYPE } from "../enums/jobType.enum";
import LeadService from "../services/lead.service";
import PrivacyPolicyRoute from "./Common/PrivacyPolicyRoute";

const leadService = new LeadService();
const OtpModal = (props: any) => {
  const { getOrganizationId, getOrgSettings, getTheme, isClientOrg } =
    useOrgTheme();
  const { setloader } = useContext(UserContext);
  const [otpValue, setOtpValue] = useState<any>(null);
  const [leadsSameAddress, setLeadsSameAddress] = useState<any>(false);
  const [leadResponse, setleadResponse] = useState<any>({});
  const [otpDisabled, setOtpDisabled] = useState<any>(false);
  const [leadsSameAddressModalIsOpen, setLeadsSameAddressModalIsOpen] =
    useState<any>(false);

  const { is_phone_number_required: isPhoneRequired } = getOrgSettings();

  const { privacyPolicyRoute } = getTheme();

  useEffect(() => {
    if (props.type == "lead") {
      if (props.leadResponse.hasOwnProperty("recipient_type")) {
        setLeadsSameAddress(true);
        setLeadsSameAddressModalIsOpen(true);
      }
      setleadResponse(props.leadResponse);
    } else {
      setLeadsSameAddress(true);
      setLeadsSameAddressModalIsOpen(true);
    }
  }, []);

  function handleChange(value: string) {
    setOtpValue(value);
    if (value.toString().length == 4) {
      verifyOtp(value);
    }
  }
  const sendOtp = async (recipient: string) => {
    const data: any = {
      phone_or_email: recipient,
      organization_id: getOrganizationId(),
      job_type: JOB_TYPE.SOLAR,
      sign_in: +(props.type === "account"),
    };

    setloader(true);
    try {
      const result = await leadService.sendOtp(data);
      setloader(false);
      if (result) {
        if (result?.otp_sent) {
          setLeadsSameAddress(false);
          setleadResponse(result);
          toast.success(result.message);
        } else if (result.hasOwnProperty("Error")) {
          toast.error(result.Error);
          if (result?.error_type === "invalid_phone") {
            props.close();
            props.setAlertModalOpen();
          }
        }
      }
    } catch (e) {
      setloader(false);
      toast.error((e as Error).message);
    }
  };

  const verifyOtp = async (value: any) => {
    setOtpDisabled(true);
    const data = {
      lead_id: leadResponse.leadID,
      token: value,
    };
    setloader(true);
    try {
      const result = await leadService.verifyOtp(data);
      if (result) {
        //  if(props.type == 'account'){
        if (leadsSameAddressModalIsOpen) {
          if (result.hasOwnProperty("allPurchases")) {
            props.setAllPurchasesForContact(result.allPurchases);
            setloader(false);
            return;
          } else if (result.hasOwnProperty("lead")) {
            toast.success("Successfully otp match");
            props.navigate(result.lead);
            return;
          } else if (result.hasOwnProperty("Error")) {
            setOtpValue(null);
            if (result.Error.includes("Reached the maximum allowed attempts")) {
              props.close();
            }
            toast.error(result.Error);
          }
        } else {
          if (result.hasOwnProperty("lead")) {
            toast.success("Successfully otp match");
            props.navigate(result.lead);
            return;
          } else if (result.hasOwnProperty("Error")) {
            setOtpValue(null);
            if (result.Error.includes("Reached the maximum allowed attempts")) {
              props.close();
            }
            toast.error(result.Error);
          }
        }
        //   }else{
        //       if (result.hasOwnProperty('lead')) {
        //           toast.success('Successfully otp match');
        //           props.navigate(result.lead);
        //           return;
        //       }else if(result.hasOwnProperty('Error')){
        //           setOtpValue(null);
        //           if(result.Error.includes('Reached the maximum allowed attempts')){
        //             props.close()
        //           }
        //           toast.error(result.Error);

        //       }
        //  }

        setloader(false);
      }
      setOtpDisabled(false);
    } catch (e) {
      setloader(false);
      setOtpDisabled(false);
      setOtpValue(null);
      toast.error((e as Error).message);
      return null;
    }
  };

  function handleResendOtp() {
    let sendOtpTo = null;
    if (!!leadResponse?.phone) {
      sendOtpTo = leadResponse.phone;
    } else if (!!leadResponse?.email) {
      sendOtpTo = leadResponse.email;
    }

    if (sendOtpTo) {
      sendOtp(sendOtpTo);
    }
  }

  function getRecipientValue() {
    if (leadResponse?.phone) {
      const phone = leadResponse.phone;
      const substringIndex = phone.startsWith("91") ? 8 : 7;
      const countryCode = substringIndex === 8 ? "91" : "1";
      return `+${countryCode}******${phone.substring(substringIndex)}`;
    } else if (leadResponse?.email) {
      const email = leadResponse.email;
      return `${email.slice(0, 4)}*****`;
    }
  }
  return (
    <div>
      <div className="lead relative flex-col-reverse md:flex-row">
        <div className="pt-8 p-4 md:p-8 md:py-14 md:px-12">
          {!leadsSameAddress && (
            <div className="otp-block">
              <div className="w-full">
                <h2 className="text-center mb-4 text-xl md:text-2xl font-primaryMedium text-titleColor">
                  Enter the 4-digit code sent to
                  <span className="text-primary block mt-1">
                    {getRecipientValue()}
                  </span>
                </h2>
              </div>
              <div className=" flex justify-center items-center">
                <OtpInput
                  inputStyle="inputStyle"
                  value={otpValue}
                  onChange={handleChange}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  // isInputNum={true}
                  // isInputSecure={true}
                  // isDisabled={otpDisabled}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                />
              </div>
              <div className="px-2 xxs:px-4">
                {/* {otpDisabled && <p>verifying</p>} */}
                {!otpDisabled && (
                  <button
                    type="button"
                    className="w-full text-center bg-primary text-white p-3 rounded mt-8 cursor-pointer"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    Close
                  </button>
                )}

                {otpDisabled && (
                  <button
                    type="button"
                    className="w-full text-center bg-primary text-white p-3 rounded mt-8"
                  >
                    Verifying
                  </button>
                )}
                <div className="flex-row flex justify-between items-center">
                  <a
                    className="text-superSecondary mt-4 cursor-pointer block text-base  "
                    onClick={handleResendOtp}
                  >
                    Resend
                  </a>
                  {props.type == "lead" && (
                    <a
                      className="text-primary mt-4 cursor-pointer block text-base  underline"
                      onClick={() => {
                        localStorage.clear();
                        props.continueAsNew();
                      }}
                    >
                      Create new project
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}

          {leadsSameAddress && (
            <div className="mobile-number-block w-full relative ">
              <div className="flex flex-row-reverse items-start gap-2 justify-between">
                <svg
                  onClick={() => {
                    props.close();
                  }}
                  className="w-8 h-8 text-bodyColor md:float-right md:absolute -top-12  -right-2 md:-right-10 mr-0.5 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
                <h2 className="text-left mb-3 text-xl md:text-2xl font-primaryMedium text-titleColor">
                  Check the status of your purchase here
                  {/* Already made a purchase? Check your status here... */}
                </h2>
              </div>
              {props?.leadResponse?.recipient_type === "email" ||
              !isPhoneRequired ? (
                <EmailForm onSubmit={sendOtp} />
              ) : (
                <PhoneForm onSubmit={sendOtp} />
              )}
              {props.type == "lead" && (
                <a
                  className="text-primary mt-4 cursor-pointer block text-base  underline"
                  onClick={() => {
                    localStorage.clear();
                    props.continueAsNew();
                  }}
                >
                  Create new project
                </a>
              )}

              <p className="font-primaryLight text-xs 2xl:text-xs text-gray-400 mt-2">
                {" "}
                {(!!(isClientOrg && privacyPolicyRoute) || !isClientOrg) && <span>By Proceeding you agree to the </span>}

                <PrivacyPolicyRoute />{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
