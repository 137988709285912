import Link from "next/link";
import { useOrgTheme } from "../../context/ThemeContext";
import MobileHeaderSQ from "./SQHeader/MobileHeader";
import MobileHeaderClient from "./Client/MobileHeader";

const MobileHeader = (props: any) => {
  const {
    openMobileView,
    handleLogoClick,
    logoUrl,
    setMobileView,
    setMenutoggle,
    openMobileMenuView,
    showGoToCurrentPurchaseButton,
    goToCurrentPurchase,
  } = props;

  const { isClientOrg, isSuntuity, getOrgSlug } = useOrgTheme();
  const isSolarQuote = getOrgSlug() === "solarquote";
  return (
    <div
      className={
        "fixed inset-0 z-110 flex xl:hidden " +
        (openMobileView ? " transform-style" : "transform-style-none")
      }
      role="dialog"
      aria-modal="true"
    >
      <div className="relative  w-full bg-white flex-1 flex flex-col z-100 min-h-screen">
        <div className="flex flex-row justify-between py-2 px-2 pl-4 items-center">
          <Link href="/">
            <a className="pl-2" onClick={handleLogoClick}>
              {logoUrl && (
                <img
                  src={`${logoUrl}`}
                  alt="Solar Quote"
                  width="190"
                  className={`object-contain cursor-pointer spl__brandLogo ${
                    isClientOrg ? "customLogoWidth" : "w-52 md:w-60"
                  } ${isClientOrg && !isSuntuity ? "vinitLogoImage" : ""}`}
                />
              )}
            </a>
          </Link>
          <button
            onClick={() => setMobileView(false)}
            type="button"
            className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white hover:shadow-none"
          >
            <svg
              className="h-10 w-10 text-bodyColor text-opacity-60"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span className="sr-only">Close sidebar</span>
          </button>
        </div>

        <div className="py-4 flex-1 pr-3 overflow-y-auto min-h-screen bg-white relative z-999">
          {isSolarQuote && (
            <MobileHeaderSQ
              setMenutoggle={setMenutoggle}
              openMobileMenuView={openMobileMenuView}
              setMobileView={setMobileView}
              showGoToCurrentPurchaseButton={showGoToCurrentPurchaseButton}
              goToCurrentPurchase={goToCurrentPurchase}
            />
          )}
          {isClientOrg && <MobileHeaderClient />}
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
