import useSWR from "swr";
import CommonService from "../../services/common.service";
import { OrganisationTheme } from "../../interfaces/Theme";
import { AxiosResponse } from "axios";
import { getCurrentDomain } from "../../utils/contentFetcher";

const service = new CommonService();

export function useOrgThemeByDomain() {
  const domain = getCurrentDomain();
  const { data, error } = useSWR(domain, orgThemeFetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });
  return {
    data,
    isLoading: !data && !error,
    error,
  };
}

async function orgThemeFetcher(domain: string) {
  const response = (await service.getThemeByDomain(domain)) as AxiosResponse<{
    data: OrganisationTheme;
  }>;
  return response.data?.data;
}
