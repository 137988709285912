const ExternalLinks = (props: any) => {
  const { data, className } = props;
  if (!data) {
    return null;
  }
  return data.map((current: any, i: any) => {
    const { link, label } = current;
    return (
      <a
        className={className}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        key={i}
      >
        {label}
      </a>
    );
  });
  // <Link href={link}>
  // </Link>
};
export default ExternalLinks
