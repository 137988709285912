import moment from "moment";
import { isMobile } from "./DeviceType";

interface Props {
  close: () => void;
  allPurchasesForContact: any;
  navigate: (leadData: any) => void;
}

const PurchasesForContactModal = (props: Props) => {
  const current = moment().startOf("day");

  const getDate = (date: string) => {
    let givenDate = moment(date, "YYYY-MM-DD");
    let days = -moment.duration(givenDate.diff(current)).asDays();
    if (days > 7) {
      return moment(date).format("DD MMM YYYY");
    }
    return moment(date).fromNow();
  };

  return (
    <>
      <div className={`lead-form-dialog ${isMobile ? 'mobileView' : ''}`}>
        <div className="sticky top-0 bg-white z-10">
          <h2 className="px-6 py-3 md:py-4 text-titleColor font-secondaryBold text-xl xxxs:text-2xl md:text-3xl mr-8">
            Your Recent <span className="text-primary"> Purchases </span>
          </h2>
          <span onClick={() => {
            props.close();
          }} className="cursor-pointer sm:w-auto absolute top-3 md:top-6 right-4 z-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" h-6 w-6 xxxs:h-7 xxxs:w-7 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </div>
        <div className="grid gap-4 p-4 md:py-4 md:px-6 lead-form-dialog__body">
          {props.allPurchasesForContact.length > 0 &&
            props.allPurchasesForContact.map((purchase: any, index: number) => (
              <div
                className="hover:bg-gray-50 cursor-pointer border-2 border-gray-200 rounded p-4 flex justify-between transition duration-500 ease-in-out transform"
                key={index}
                onClick={() => {
                  props.close();
                  props.navigate(purchase);
                }}
              >
                <span>
                  <p className="text-titleColor font-primaryMedium text-base">
                    {purchase.full_address}
                  </p>
                  <p className="text-gray-400 text-sm">
                    {" "}
                    {getDate(purchase.created_at)}
                  </p>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-bodyColor h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default PurchasesForContactModal;
