import Link from "next/link";
import { useOrgTheme } from "../../../context/ThemeContext";
import BecomeAffilateLink from "../Common/BecomeAffilateLink";
import ExternalLinks from "../Common/ExternalLinks";

const MenuIcon = (props: any) => {
  const { setMobileView, openMobileView } = props;
  const { getOrgSettings } = useOrgTheme();
  const { show_affialiate, external_links } = getOrgSettings();
  if (!(external_links?.length > 0 || show_affialiate)) {
    return <></>;
  }
  return (
    <svg
      className="w-7 h-7 text-bodyColor mt-1"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => setMobileView(!openMobileView)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16m-7 6h7"
      ></path>
    </svg>
  );
};
const DesktopHeaderClient = (props: any) => {
  const {
    isSuntuity,
    handleLogoClick,
    isStateNewJersey,
    logoUrl,
    setMobileView,
    openMobileView,
    signIn,
    hideCurrentPurchaseAndTrackYourPurchaseButtons,
  } = props;
  const { getOrgSettings, getTheme, isClientOrg } = useOrgTheme();
  const { show_affialiate, external_links } = getOrgSettings();
  const { contactNumbers = [] } = getTheme();
  const contactNumber: any = contactNumbers[0];
  return (
    <header className="main-header">
      <nav
        className={` w-full shadow top-0 z-40 bg-white  px-4 md:px-8 xl:px-12 xxl:px-20 flex flex-row justify-between py-3 sm:py-4 flex-col sm:flex-row`}
      >
        <div className={`justify-between flex`}>
          <Link href="/">
            <a className="logo-img z-100" onClick={handleLogoClick}>
              {logoUrl && (
                <img
                  src={`${logoUrl}`}
                  alt="Solar Quote"
                  className={`object-contain cursor-pointer spl__brandLogo customLogoWidth h-full w-full allwidth ${
                    isSuntuity ? "allwidth" : ""
                  } `}
                />
              )}
            </a>
          </Link>
          <span className="sm:hidden">
            <MenuIcon
              setMobileView={setMobileView}
              openMobileView={openMobileView}
            />
          </span>
        </div>
        {/* <HeaderRight
            contactNumber={contactNumber}
            isStateNewJersey={isStateNewJersey}
            signIn={signIn}
            setMobileView={setMobileView}
            openMobileView={openMobileView}
            hideCurrentPurchaseAndTrackYourPurchaseButtons={
              hideCurrentPurchaseAndTrackYourPurchaseButtons
            }
          /> */}
        <div className="flex items-center gap-3 justify-end">
          <div className="hidden xl:flex items-center justify-end md:justify-left gap-3">
            <ExternalLinks
              data={external_links}
              className="mt-2 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer text-titleColor"
            />
            {show_affialiate && (
              <BecomeAffilateLink className="mt-2 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center  hover:text-primary hover:no-underline cursor-pointer text-titleColor" />
            )}
          </div>
          <div className="flex flex-row items-end space-x-4 xxs:space-x-4 2xl:space-x-6 justify-center xxs:items-center md:justify-center">
            {contactNumber && (
              <Link href={`tel:${contactNumber}`}>
                <a className="pt-2 md:pt-0 mt-2 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium flex items-center justify-center text-titleColor">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="leading-tight">{contactNumber}</span>
                </a>
              </Link>
            )}
            {!hideCurrentPurchaseAndTrackYourPurchaseButtons && (
              <span
                className={`flex items-center justify-center mt-0 xxs:mt-3 md:mt-0 text-sm lg:text-base 2xl:text-lg font-primaryMedium text-center cursor-pointer ${
                  isStateNewJersey ? "text-superSecondary" : "text-primary"
                } ${
                  isClientOrg && !isSuntuity
                    ? "text-superSecondary"
                    : "text-primary"
                }`}
              >
                <svg
                  className="w-6 h-6 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  ></path>
                </svg>
                <span className="leading-tight" onClick={signIn}>
                  Track purchase
                </span>
              </span>
            )}
            <span className="sm:block hidden xl:hidden">
              <MenuIcon
                setMobileView={setMobileView}
                openMobileView={openMobileView}
              />
            </span>
            {/* {external_links?.length > 0 && (
              <svg
                className="sm:block hidden xl:hidden w-7 h-7 text-bodyColor"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setMobileView(!openMobileView)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )} */}
          </div>
        </div>
      </nav>
    </header>
  );
};
export default DesktopHeaderClient;
