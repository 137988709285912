import React, { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRouter } from "next/router";

import { UserContext } from "../UserContext";
import { AffiliateRoutes } from "../../pages/ambassador";
import { useOrgTheme } from "../../context/ThemeContext";

interface Props {
  openLoginModal?: () => void;
  closeForgotPasswordModal: () => void;
}
const toastOptions = {
  autoClose:3000
}
const ReferralForgotPasswordModal = (props: Props) => {
  const { setloader } = useContext(UserContext);
  const usernameInputRef = useRef<any>();
  const router = useRouter();
  const { getOrganizationId, getHostnameID, isPreviewDomain } = useOrgTheme();
  const [forgotPasswordProgress, setForgotPasswordProgress] = useState<boolean>(false);
  const sectionID= 'referral-forgot-password-model';
  const handleLoginClick = () => {
    router.push({ pathname: AffiliateRoutes.login });
  };

  const handleModalClose = () => {
    props.closeForgotPasswordModal();
    router.push({ pathname: AffiliateRoutes.index });
  };

  const handleForgotPassword = async () => {
    setloader(true);
    try {
      const username = usernameInputRef?.current?.value;
      if (!username) {
        throw new Error("Enter valid username.");
      }

      const res = await fetch(
        process.env.API_URL + "referral/forgot-password",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            organization_id: getOrganizationId(),
            hostname_id: getHostnameID(),
            is_preview: isPreviewDomain()
          }),
        }
      );
      const result: any = await res.json();
      if (res.ok) {
        if (result) {
          setForgotPasswordProgress(true);
        }
      }

      if (result?.errors?.length) {
        throw new Error(result.errors[0]);
      }
    } catch (e: any) {
      setloader(false);
      toast.error(e.message || "Something Went worng. Try again later",toastOptions);
      return null;
    } finally {
      setloader(false);
    }
  };

  return (
    <div id={sectionID} className="">
      <div className="lead relative flex-col-reverse md:flex-row">
        <div className="pt-16 p-4 md:p-8 md:py-14 md:px-12">
          <div className="relative">
            <svg
              onClick={() => {
                handleModalClose();
              }}
              className="w-8 h-8 text-bodyColor float-right absolute -top-12  -right-2 md:-right-10 mr-0.5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
            <h2 className="text-left mb-3 text-2xl font-primaryMedium text-titleColor">
              Forgot Password
            </h2>

            {!forgotPasswordProgress ?
              <>

                <div className="grid grid-cols-1 gap-4">
                  <div className="relative">
                    <p className="mb-1 text-sm xxs:text-base text-titleColor">
                      Username <span className="text-primary"> *</span>
                    </p>
                    <input
                      id={`${sectionID}-username`}
                      ref={usernameInputRef}
                      placeholder="username"
                      name="username"
                      className="text-sm mt-1 md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2.5 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                    />
                  </div>
                </div>

                <button
                  id={`${sectionID}-submit-btn`}
                  onClick={handleForgotPassword}
                  type="submit"
                  className="disabled:opacity-50 block p-3 my-4 mt-8 w-full hover:shadow-lg bg-primary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center rounded"
                >
                  Send Link
                </button>

                <div className="my-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-white px-2 text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    handleLoginClick();
                  }}
                  id={`${sectionID}-back-to-login-btn`}
                  type="submit"
                  className="disabled:opacity-10 block p-3 w-full hover:shadow-lg bg-secondary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center rounded"
                >
                  Login
                </button>

              </> :
              <div className="flex flex-col gap-1 mt-8">
                <span className="flex items-center text-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-14 h-14 text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </span>
                <h3 className="mb-2 text-base text-titleColor font-interRegular text-center">
                  An email has been sent to your registered email address. Please check your inbox and click the provided password reset link.
                </h3>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralForgotPasswordModal;
