import React, { ReactNode, useEffect, useState } from "react";
import Head from "next/head";
import { useOrgTheme } from "../context/ThemeContext";
import Script from "next/script";

type Props = {
  children?: ReactNode;
  title?: string;
  description?: string;
  keywords?: string;
  socialMediaMetaData?: {
    url: string;
    description: string;
    title: string;
    image: string;
  };
};

const Layout = ({
  children,
  title = "Get Your Free Estimate | Solar Quote",
  description = "Going solar has never been so simple. Get your instant and accurate Solar Quote today! Solar Quote is the best solar panel installation company in USA.",
  keywords = "domestic solar panels, efficiency of solar energy, most efficient solar panel, best solar panels for home use, benefits of using solar energy",
  socialMediaMetaData,
}: Props) => {
  const [canonicalUrl, setCanonicalUrl] = useState<string>("");
  useEffect(() => {
    if (typeof window !== undefined) {
      let currentUrl = window.location.hostname;
      if (window.location.pathname !== "/") {
        currentUrl = currentUrl + window.location.pathname;
      }
      setCanonicalUrl(currentUrl);
    }
  }, []);

  const { getOrgFavicon, isClientOrg } = useOrgTheme();
  const favicon = getOrgFavicon();
  
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} charSet="utf-8" />
        <meta name="keywords" content={keywords} />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1"
        />
        <link rel="icon" type="image/x-icon" href={favicon} />
        <link rel="canonical" href={canonicalUrl} />
        <link
          href="https://cdn.quilljs.com/1.0.0/quill.snow.css"
          rel="stylesheet"
        />

        {socialMediaMetaData && (
          <>
            {/* Facebook */}
            <meta property="og:url" content={socialMediaMetaData.url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={socialMediaMetaData.title} />
            <meta
              property="og:description"
              content={socialMediaMetaData.description}
            />
            <meta property="og:image" content={socialMediaMetaData.image} />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="solarquote.com" />
            <meta property="twitter:url" content={socialMediaMetaData.url} />
            <meta name="twitter:title" content={socialMediaMetaData.title} />
            <meta
              name="twitter:description"
              content={socialMediaMetaData.description}
            />
            <meta name="twitter:image" content={socialMediaMetaData.image} />
          </>
        )}
        {!isClientOrg && (
          <>
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CDDEKD3C77U8LA9AU2MG');
  ttq.page();
}(window, document, 'ttq');`,
              }}
            />
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Solar Quote",
    "url": "https://www.solarquote.com/",
    "logo": "https://www.solarquote.com/images/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "385-367-5974",
      "contactType": "customer service",
      "areaServed": "US",
      "availableLanguage": "en"
    },
    "sameAs": [
      "https://www.facebook.com/solarquoteinc",
      "https://www.instagram.com/solarquoteinc/",
      "https://www.youtube.com/@solarquoteinc",
      "https://www.linkedin.com/company/solarquote/"
    ]
  }
  
  `,
              }}
            ></script>
            <script>{` (function(d,s,i){var f,j;f=d.getElementsByTagName(s)[0];j=d.createElement(s);j.async=true;j.src='https://mtag.microsoft.com/tags/'+i+'.js';j.referrerPolicy='no-referrer-when-downgrade';f.parentNode.insertBefore(j,f);})(document,'script','13881334301200'); `}</script>
            <Script
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PH6T2V6');`,
              }}
            ></Script>
          </>
        )}
      </Head>
      <div className="">{children}</div>
    </>
  );
};

export default Layout;
