import Link from "next/link";
import { useOrgTheme } from "../../context/ThemeContext";

const PrivacyPolicyRoute = (props: any) => {
  const { isClientOrg, getTheme } = useOrgTheme();
  const { privacyPolicyRoute } = getTheme();
  const defaultClassName = `underline cursor-pointer ml-1 ${
    isClientOrg ? "text-primary" : "text-secondary"
  }`;
  const { className = defaultClassName, text="Privacy Policy" } = props;

  const route = isClientOrg ? privacyPolicyRoute : "/privacy";
  if (isClientOrg && !privacyPolicyRoute) {
    return <></>;
  }
  return (
    <Link href={`${route}`}>
      <a className={className} target="_blank">
        {text}
      </a>
    </Link>
  );
};
export default PrivacyPolicyRoute;
