import * as yup from "yup";
import { capitalizeFirstLetter } from "../../utils/common.function.utils";

export function getNameValidation(field: string, isRequired = true) {
  const fieldName = capitalizeFirstLetter(field);
  const yupValidation = yup
    .string()
    .matches(
      /^\s*[A-Za-z]+\s*$/,
      `${fieldName} Name is invalid`
    )
  return isRequired ? yupValidation.required(`${fieldName} name is required`) : yupValidation;
}

export function emailValidation(isRequired = true) {
  const validation: any = yup
    .string()
    .test("email", "Email is required", (value) => {
      if (!value && isRequired) {
        return false;
      }
      return true;
    }).email("Invalid email address")

  return validation;
};