export const loadScripts = (
  scripts: Array<{
    label: string;
    script: string;
    sortOrder: number;
    includesIn: "head" | "body";
  }>
) => {
  for (let i = 0; i < scripts.length; i++) {
    const element = scripts[i];
    try {
      const code = element?.script;
      const tags = extractTags(code);

      if (!tags?.length) {
        eval(code);
        continue;
      }
      tags.forEach((tag) => {
        // Handle <script> tag
        let scriptToAdd: any = null;
        switch (tag.tagName) {
          case "SCRIPT": {
            const newScript = document.createElement("script");

            Array.from(tag.attributes).forEach((attr) => {
              newScript.setAttribute(attr.name, attr.value);
            });

            if (tag.textContent) {
              newScript.textContent = tag.textContent;
            }

            scriptToAdd = newScript;
            break;
          }

          case "NOSCRIPT": {
            // const noscriptContent = tag.textContent || "";
            // const fragment = document.createDocumentFragment();
            // const tempDiv = document.createElement("div");
            // tempDiv.innerHTML = noscriptContent; 
            // while (tempDiv.firstChild) {
            //   fragment.appendChild(tempDiv.firstChild);
            // }
            // Prepend the fragment to the body
            document.body.prepend(tag);

            break;
          }

          default:
            // For other HTML elements, append directly
            if (element?.includesIn === "body") {
              document.body.append(tag);
            } else {
              document.head.append(tag);
            }
            return; // Exit early as we don't need to append further
        }

        if (!scriptToAdd) return;
        if (element?.includesIn === "body") {
          document.body.append(scriptToAdd);
        } else {
          document.head.append(scriptToAdd);
        }
      });
    } catch (e) {
      console.log(i);
      console.log(e);
    }
  }
};

// Extract all <script>, <noscript>, and other HTML tags from the provided script string
const extractTags = (element: any) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = element;

  // Extract all <script>, <noscript>, and other HTML elements
  const scriptTags = Array.from(tempDiv.querySelectorAll("script"));
  const noScriptTags = Array.from(tempDiv.querySelectorAll("noscript"));
  const otherTags = Array.from(tempDiv.children).filter(
    (child) => child.tagName !== "SCRIPT" && child.tagName !== "NOSCRIPT"
  );

  // Combine the NodeList from all queries into a single array
  return [...scriptTags, ...noScriptTags, ...otherTags];
};
