import { useEffect, useState } from "react";
import Modal from "react-modal";
import SocialMediaShare from "../../Common/SocialMediaShare";
import Link from "next/link";
import { useRouter } from "next/router";
import { useOrgTheme } from "../../../context/ThemeContext";
const ReminderPopup = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [url, setUrl] = useState("");
	const { getOrgSettings } = useOrgTheme();
	const { show_affialiate } = getOrgSettings() || {};

  const closeModal = () => {
    setShowConfirmation(false);
  };
  const handleBeforeUnload = function (e: any) {
    const firstLogin = localStorage.getItem("firstLogin");
    if (firstLogin === "false" && show_affialiate) {
      let confirmationMessage = "Are you sure you want to leave?";
      e.preventDefault();
      e.returnValue = confirmationMessage;
      setShowConfirmation(true);
      return confirmationMessage;
    }
  };
  useEffect(() => {
	
		window.addEventListener("beforeunload", handleBeforeUnload);
		
    // window.addEventListener("unload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload,show_affialiate]);
  useEffect(() => {
    const username = localStorage?.getItem("username");
    const url = `${window.origin}/referral/${username}`;
    setUrl(url);
  }, []);

  const router = useRouter();

  useEffect(() => {
    console.log("handleBeforePopState effect");
    const handleBeforePopState = (e: any) => {
      // { url, as, options }
      const firstLogin = localStorage.getItem("firstLogin");
      console.log(e);
      const { url } = e;
      const isAffiliatePage = url.startsWith("/ambassador")
      if (!isAffiliatePage && firstLogin === "false" && show_affialiate) {
        const shouldNavigate = window.confirm("Do you want to leaves?");
        if (!shouldNavigate) {
          // Prevent the popstate event to prevent navigation
          // router.
          window.history.forward()
          // router.push(router.asPa th, undefined, { shallow: true });
					setShowConfirmation(true);

          return false;
        }
      }
      return true;
    };
    router.beforePopState(handleBeforePopState);
    return () => {
      router.beforePopState(() => {
        return true;
      });
    };
  }, []);

  if (showConfirmation) {
    return (
      <Modal
        preventScroll={false}
        isOpen={showConfirmation}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => {
          setShowConfirmation(false);
        }}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        overlayClassName={`EstimateDetailModal__Overlay form-control-overlay`}
        ariaHideApp={false}
      >
        <div className="bg-white  rounded-md w-full max-w-xl shadow xxs:shadow-lg-g mx-auto relative">
          <div className="flex flex-col items-center justify-center py-6 sm:py-8 lg:py-10 px-4 xxs:px-6 sm:px-12 md:px-16">
            <span
              className="absolute right-4 top-4 cursor-pointer"
              onClick={closeModal}
            >
              <svg
                width="69"
                height="69"
                className="w-5 h-5"
                viewBox="0 0 69 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3L66 66"
                  stroke="#5F6F83"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M66 3L3 66"
                  stroke="#5F6F83"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            <h3 className="text-titleNewColor font-sfSemiBold text-2xl sm:text-4xl mb-1">
              Before you go
            </h3>
            <p className="px-0 text-textTitleColor font-sfMedium pt-0 pb-2 text-lg sm:text-xl text-center leading-tight">
              Don’t forget to share your unique referral link on your social
              networks.
              <Link href={url}>
                <a
                  className="inline-flex justify-center w-auto text-secondary font-sfSemiBold mt-2 pt-2 pb-2 px-3 text-lg sm:text-xl text-center leading-tight break-all bg-gray-50 rounded mx-2"
                  target="_blank"
                >
                  {url}
                </a>
              </Link>
            </p>
            <p className="text-darkblue250 font-sfLight py-2 text-xl text-center leading-tight">
              You can earn an average of{" "}
              <span className="text-primary text-2xl font-sfSemiBold underline">
                $1,050
              </span>{" "}
              for each solar purchase online.
            </p>
            <p className="text-textTitleColor font-sfMedium pt-2 pb-3 text-lg text-center px-2 sm:px-6 leading-tight">
              Share on your stories, posts, and tweets using the buttons below
            </p>
            <SocialMediaShare url={url} />
          </div>
        </div>
      </Modal>
    );
  }
  return <></>;
};
export default ReminderPopup;
