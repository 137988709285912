import {useFormik} from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';

interface FormProp{
  onSubmit: (val: string) => void;
}

export const PhoneForm = ({ onSubmit }:FormProp) => {
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: yup.object({
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .matches(/^(?:([1])\d{10}|([9][1][789])\d{9})$/, 'Invalid Phone number'),
    }),
    onSubmit: (values) => {
      onSubmit(values.phoneNumber);
    },
  });

  function setPhoneValue(val:any){
    formik.setFieldValue("phoneNumber", val);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
     <div className="flex flex-col  relative">
      <label className="text-base text-bodyColor font-primaryMedium pb-1">Enter Phone Number</label>
      <PhoneInput
        inputProps={{
          name: 'phoneNumber',
        }}
        
        autoFormat={false}
        // disableCountryCode={true}
        countryCodeEditable={false}
        placeholder="Enter your mobile number"
        country={'us'}
        onlyCountries={['us', 'in']}
        value={formik.values.phoneNumber}                    
        onChange={(val)=>{setPhoneValue(val)}}
          onBlur={formik.handleBlur("phoneNumber")}
          
          />
      {formik.touched.phoneNumber && formik.errors.phoneNumber ? <span className="text-red-500 text-xs mt-1 w-full absolute -bottom-5">{formik.errors.phoneNumber}</span> : null} 
      </div>
      <button type="submit" className="w-full text-center bg-primary text-white p-3 rounded mt-8 " >Continue</button>
    </form>
  );
};

export const EmailForm = ({ onSubmit }:FormProp) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().required('Email Address is required').email('Invalid email address'),
    }),
    onSubmit: (values) => {
      onSubmit(values.email);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
     <div className="flex flex-col  relative">
      <label className="text-base text-bodyColor font-primaryMedium pb-1">Enter Email Address</label>
        <input
          type="text"
          name="email"
          placeholder='ex: abc@example.com'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="text-sm md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
        />
       {formik.touched.email && formik.errors.email ? <span className="text-red-500 text-xs mt-1 w-full absolute -bottom-5">{formik.errors.email}</span> : null} 
      </div>
      <button type="submit" className="w-full text-center bg-primary text-white p-3 rounded mt-8 " >Continue</button>
    </form>
  );
};


