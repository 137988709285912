import React from "react";

interface Props {
  onRefresh:() => void;
  onCloseTab:() => void;
}
const NewPruchaseAlertModal = (props: Props) => {
  const heading='Alert';
  const text='New project has been started in a new tab. Please refresh the page to switch to that project.'

  // function onCloseTab1(){
  //   let popup:any = window.open('moreinfo.html','_self');
  //   setTimeout(()=>{})
  //   popup?.close();
  // }

  return (
    <section className="confirm-box relative md:container md:m-auto mt-6 md:mt-0">
      <div className="bg-white px-4 sm:px-6 sm:mb-0 mb-4 overflow-hidden">
        <div className="mb-4">
          <h2 className="text-xl text-titleColor font-primaryMedium">
            {" "}
            {heading}
          </h2>

          <p className="text-titleColor text-base pt-2 xxs:pt-3">
            {" "}
            {text}{" "}
          </p>
        </div>
        <div className="sm:justify-end sm:items-center dialog-footer flex flex-row xxs:border-t xxs:py-2 border-opacity-5 border-bodyColor">
          <div className="w-full sm:w-auto flex justify-between xxs:justify-end">
            <button
              className="mb-2 md:mb-0 w-full xxs:w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 sm:px-4 py-2 bg-primary font-primaryMedium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
              onClick={() => {
                props.onRefresh();
              }}
            >
              Refresh
            </button>
          </div>

          {/* <div className="ml-5 w-full sm:w-auto flex justify-between xxs:justify-end">
            <button
              className="mb-2 md:mb-0 w-full xxs:w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 sm:px-4 py-2 bg-primary font-primaryMedium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
              // onClick={() => {
              //   props.onCloseTab();
              // }}
              onClick={onCloseTab1}
            >
              Close tab
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default NewPruchaseAlertModal;